import { invariantResponse } from '@epic-web/invariant'
import {
	redirect,
	type LoaderFunctionArgs,
	type MetaFunction,
} from '@remix-run/node'
import { Link, json, useLoaderData } from '@remix-run/react'
import { type ColumnDef } from '@tanstack/react-table'
import { PiIdentificationBadge } from 'react-icons/pi'
import { z } from 'zod'
import { zx } from 'zodix'
import GenericErrorBoundary from '#app/components/error-boundary'
import Hero from '#app/components/hero'
import { SearchQuery } from '#app/components/search/search-query'
import { Avatar, AvatarFallback, AvatarImage } from '#app/components/ui/avatar'
import { DataTable } from '#app/components/ui/data-table'
import HeaderSimple from '#app/components/ui/header'
import { useHints } from '#app/utils/client-hints'
import { getApiClient } from '#app/utils/service-api.server'
import { getInitials } from '#app/utils/string'
import { type User, type UserWithMetrics } from '#types/api-client.types.gen'
import { userOrderingSchema } from '#types/api-client.types.zod'
import { isUserWithMetrics } from '#types/guards'
import { listUsers } from '#app/.server/api-client/sdk.gen'

const DEFAULT_PAGE_SIZE = 25

export const meta: MetaFunction = () => [{ title: 'Users | Moonhub' }]

// Extract the types for order_by and order_direction
type OrderByType = z.infer<typeof userOrderingSchema.shape.order_by>
type OrderDirectionType = z.infer<
	typeof userOrderingSchema.shape.order_direction
>

// Define the sort by schema with transformation and validation
const sortBySchema = z
	.string()
	.transform((value) => {
		const [order_by = 'name', order_direction = 'asc'] = value.split(
			'.',
		) as [OrderByType, OrderDirectionType]
		return { order_by, order_direction }
	})
	.refine(
		({ order_by, order_direction }) => {
			return (
				userOrderingSchema.shape.order_by.safeParse(order_by).success &&
				userOrderingSchema.shape.order_direction.safeParse(
					order_direction,
				).success
			)
		},
		{
			message: 'Invalid sort format or values.',
		},
	)

export async function loader({ request }: LoaderFunctionArgs) {
	try {
		const client = await getApiClient(request)

		const {
			page = 0,
			sort,
			q: query,
		} = zx.parseQuery(request, {
			page: z.coerce.number().default(0),
			sort: sortBySchema.default('name.asc'),
			q: z.string().optional().nullable(),
		})
		const { order_by, order_direction } = sort

		const { data: users } = await listUsers({
			client,
			body: {
				pagination: {
					page_size: DEFAULT_PAGE_SIZE,
					page,
				},
				ordering: [
					{
						order_by,
						order_direction,
					},
				],
				filters: {
					query,
				},
				include_metrics: true,
			},
		})
		invariantResponse(
			users?.entries && users?.metadata,
			"Couldn't load users",
		)

		// redirect to the first page we have a page number and no results
		if (users.entries.length === 0 && page > 0) {
			return redirect('/admin/users')
		}

		return json({
			users: users.entries,
			metadata: users.metadata,
		})
	} catch (error) {
		throw error
	}
}

export const columns: ColumnDef<User | UserWithMetrics>[] = [
	{
		accessorKey: 'name',
		header: 'Name',
		enableSorting: true,
		cell: ({ row }) => {
			return (
				<div className="flex items-center gap-x-2">
					<Avatar className="flex h-8 w-8 flex-none items-center justify-center rounded-full bg-gray-200 text-lg text-gray-600">
						{row.original.picture && (
							<AvatarImage
								src={row.original.picture}
								className="rounded-full"
							/>
						)}
						<AvatarFallback>
							{getInitials(row.original.name)}
						</AvatarFallback>
					</Avatar>
					<div className="flex flex-grow flex-col gap-y-0.5">
						<div className="flex flex-grow flex-row gap-x-3">
							<div className="flex">
								<Link
									className="flex flex-1"
									to={`/admin/users/${row.original.id}`}
								>
									{row.original.name}
								</Link>
							</div>
						</div>
					</div>
				</div>
			)
		},
	},
	{
		accessorKey: 'email',
		header: 'Email address',
		enableSorting: false,
		cell: ({ row }) => {
			return <div>{row.original.email}</div>
		},
	},
	{
		accessorKey: 'job_count',
		header: 'Jobs',
		enableSorting: false,
		cell: ({ row }) => {
			if (!isUserWithMetrics(row.original) || !row.original.job_count) {
				return <div>-</div>
			}

			const numberFormatter = new Intl.NumberFormat(undefined)
			return <div>{numberFormatter.format(row.original.job_count)}</div>
		},
	},
	{
		accessorKey: 'candidate_count',
		header: 'Candidates',
		enableSorting: false,
		cell: ({ row }) => {
			if (
				!isUserWithMetrics(row.original) ||
				!row.original.candidate_count
			) {
				return <div>-</div>
			}

			const numberFormatter = new Intl.NumberFormat(undefined)
			return (
				<div>
					{numberFormatter.format(row.original.candidate_count)}
				</div>
			)
		},
	},
	{
		accessorKey: 'created_at',
		header: 'Created Date',
		enableSorting: true,
		cell: function CellComponent({ row }) {
			const { timeZone } = useHints()
			const display = new Intl.DateTimeFormat(undefined, {
				timeZone,
				dateStyle: 'medium',
			}).format(new Date(row.getValue('created_at')))

			return <div>{display}</div>
		},
	},
]

export default function UsersIndex() {
	const { users, metadata } = useLoaderData<typeof loader>()

	return (
		<div className="flex h-full flex-1 flex-col gap-x-5">
			<HeaderSimple
				title="Users"
				subtitle={
					'View users and the jobs and companies they are associated with.'
				}
				className="flex flex-none"
			/>
			<SearchQuery name="q" placeholder="Search users" autoFocus />
			<div className="flex-grow overflow-hidden">
				{!users || users.length === 0 ? (
					<Hero
						icon={PiIdentificationBadge}
						title="No users"
						description="You must be an engineer because this shouldn't be possible..."
					/>
				) : (
					<DataTable
						data={users}
						columns={columns}
						totalRows={
							metadata?.pagination?.total_count || users.length
						}
						pageSize={DEFAULT_PAGE_SIZE}
						sortProps={{
							initialField: metadata?.ordering?.[0]?.order_by,
							initialOrder:
								metadata?.ordering?.[0]?.order_direction,
						}}
					/>
				)}
			</div>
		</div>
	)
}

// need the generic error boundary
export const ErrorBoundary = GenericErrorBoundary
